@font-face {
  font-family: 'gtareg';
  src: url('../fonts/GT-America-Standard-Regular.eot');
  src: url('../fonts/GT-America-Standard-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-America-Standard-Regular.woff2') format('woff2'),
  url('../fonts/GT-America-Standard-Regular.woff') format('woff'),
  url('../fonts/GT-America-Standard-Regular.ttf')  format('truetype');
}

@font-face {
  font-family: 'gtamed';
  src: url('../fonts/GT-America-Standard-Medium.eot');
  src: url('../fonts/GT-America-Standard-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-America-Standard-Medium.woff2') format('woff2'),
  url('../fonts/GT-America-Standard-Medium.woff') format('woff'),
  url('../fonts/GT-America-Standard-Medium.ttf')  format('truetype');
}

@font-face {
  font-family: 'gtabold';
  src: url('../fonts/GT-America-Standard-Bold.eot');
  src: url('../fonts/GT-America-Standard-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-America-Standard-Bold.woff2') format('woff2'),
  url('../fonts/GT-America-Standard-Bold.woff') format('woff'),
  url('../fonts/GT-America-Standard-Bold.ttf')  format('truetype');
}

@font-face {
  font-family: 'gtamonoreg';
  src: url('../fonts/GT-America-Mono-Regular.eot');
  src: url('../fonts/GT-America-Mono-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GT-America-Mono-Regular.woff2') format('woff2'),
  url('../fonts/GT-America-Mono-Regular.woff') format('woff'),
  url('../fonts/GT-America-Mono-Regular.ttf')  format('truetype');
}

