$colorTextDefault: black;

$colorBlue: #0000ff;

// Body
$colorBgBodyRtec1: white;

$paddingHorMain: 25;

// Header
$headerHeight: 120;
$headerHeightMedium: 95;
$headerHeightSmall: 70;

$headerImageHeight: 775;
$headerImageHeightMedium: 517;
$headerImageHeightSmall: 415;

$scaleMedium: ($headerHeightMedium/$headerHeight);
$scaleSmall: ($headerHeightSmall/$headerHeight);

$colorBgHeaderRtec1: white;
$colorTextHeaderRtec1: black;

$colorBgHeaderContentRtec1: black;
$colorTextHeaderContentRtec1: white;

$colorTextSocialMedia: black;
$colorTextSocialMediaContent: white;

$colorTextClaim1: #313fe1;
$colorTextClaim2: #d612c5;


// Footer
$colorBgFooterRtec1: black;
$colorTextFooterRtec1: white;
$colorBorderFooterRtec1: white;

$colorBgFooterContentRtec1: black;
$colorTextFooterContentRtec1: white;
$colorBorderFooterContentRtec1: white;

// Articles

$colorBgHeadlineContent: $colorBlue;
$colorTextHeadlineContent: white;

$colorBgQuoteDark: black;
$colorTextQuoteDark: white;

// Font sizes

$fontsizeH1Large: 95;
$fontsizeH1Medium: 75;
$fontsizeH1Small: 44;

$fontsizeH2Large: 60;
$fontsizeH2Medium: 44;

$fontsizeH1AwLarge: 80;
$fontsizeH1AwMedium: 60;
$fontsizeH1AwSmall: 44;


// Lists

$colorDotStandard: black;

// Elements

$colorBgAdwords1: #313fe1;
$colorBgAdwords2: #01e57c;


// Accordion

$colorBgAccStyle1: #ffffff; // Magier
$colorBgAccStyle2: #ffffff; // Weggefährte
$colorBgAccStyle3: #ffffff; // Krieger
$colorBgAccStyle4: #ffffff; // Jäger
$colorBgAccStyle5: #ffffff; // Spieler
$colorBgAccStyle6: #ffffff; // Querdenker
$colorBgAccStyle7: #ffffff; // Kontakt

/*
$colorBgAccStyle7: #5a03d6;  //Consultant
$colorBgAccStyle8: #33d301;  // Security Expert
$colorBgAccStyle9: #09a1e4;  // Security Analyst
$colorBgAccStyle10: #02068f;  // Trainee
$colorBgAccStyle11: #1f28de;  // Quereinsteiger
$colorBgAccStyle12: #019601;  // Studenten
$colorBgAccStyle13: #d612c5;  // Rufen Sie an
*/

$colorBgAccStyleHigh1: #0000ff; // Magier
$colorBgAccStyleHigh2: #00d2be; // Weggefährte
$colorBgAccStyleHigh3: #6e2dc8; // Krieger
$colorBgAccStyleHigh4: #a0e128; // Jäger
$colorBgAccStyleHigh5: #b40f82; // Spieler
$colorBgAccStyleHigh6: #fff000; // Querdenker
$colorBgAccStyleHigh7: #000000; // Kontakt

/*
$colorBgAccStyleHigh7: #5a03d6;  //Consultant
$colorBgAccStyleHigh8: #33d301;  // Security Expert
$colorBgAccStyleHigh9: #09a1e4;  // Security Analyst
$colorBgAccStyleHigh10: #02068f;  // Trainee
$colorBgAccStyleHigh11: #1f28de;  // Quereinsteiger
$colorBgAccStyleHigh12: #019601;  // Studenten
$colorBgAccStyleHigh13: #d612c5;  // Rufen Sie an
*/

$colorTextAccStyleHigh1: #ffffff; // Magier
$colorTextAccStyleHigh2: #ffffff; // Weggefährte
$colorTextAccStyleHigh3: #ffffff; // Krieger
$colorTextAccStyleHigh4: #ffffff; // Jäger
$colorTextAccStyleHigh5: #ffffff; // Spieler
$colorTextAccStyleHigh6: #000000; // Querdenker
$colorTextAccStyleHigh7: #ffffff; // Kontakt

//$accStyle: (1: $colorBgAccStyle1, 2: $colorBgAccStyle2, 3: $colorBgAccStyle3, 4: $colorBgAccStyle4, 5: $colorBgAccStyle5, 6: $colorBgAccStyle6, 7: $colorBgAccStyle7, 8: $colorBgAccStyle8, 9: $colorBgAccStyle9, 10: $colorBgAccStyle10, 11: $colorBgAccStyle11, 12: $colorBgAccStyle12, 13: $colorBgAccStyle13);
//$accStyleHigh: (1: $colorBgAccStyleHigh1, 2: $colorBgAccStyleHigh2, 3: $colorBgAccStyleHigh3, 4: $colorBgAccStyleHigh4, 5: $colorBgAccStyleHigh5, 6: $colorBgAccStyleHigh6, 7: $colorBgAccStyleHigh7, 8: $colorBgAccStyleHigh8, 9: $colorBgAccStyleHigh9, 10: $colorBgAccStyleHigh10, 11: $colorBgAccStyleHigh11, 12: $colorBgAccStyleHigh12, 13: $colorBgAccStyleHigh13);
$accStyle: (1: $colorBgAccStyle1, 2: $colorBgAccStyle2, 3: $colorBgAccStyle3, 4: $colorBgAccStyle4, 5: $colorBgAccStyle5, 6: $colorBgAccStyle6, 7: $colorBgAccStyle7);
$accStyleHigh: (1: $colorBgAccStyleHigh1, 2: $colorBgAccStyleHigh2, 3: $colorBgAccStyleHigh3, 4: $colorBgAccStyleHigh4, 5: $colorBgAccStyleHigh5, 6: $colorBgAccStyleHigh6, 7: $colorBgAccStyleHigh7);
$accTextStyleHigh: (1: $colorTextAccStyleHigh1, 2: $colorTextAccStyleHigh2, 3: $colorTextAccStyleHigh3, 4: $colorTextAccStyleHigh4, 5: $colorTextAccStyleHigh5, 6: $colorTextAccStyleHigh6, 7: $colorTextAccStyleHigh7);

$paddingLeftAccordion: 350;
