.article_dev_name {
  display: none;
  position: relative;
  z-index: 1;
}

@include breakpoint('medium down') {
  .article_dev_name {
    z-index: 2;
  }
}

body, td {
  @include font-default;
  color: $colorTextDefault;
}

a {
  color: $colorTextDefault;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin:0; padding:0;
}

p > strong {
  @include font-bold;
}


