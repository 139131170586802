@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?kn1f76');
  src:  url('../fonts/icomoon.eot?kn1f76#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?kn1f76') format('truetype'),
  url('../fonts/icomoon.woff?kn1f76') format('woff'),
  url('../fonts/icomoon.svg?kn1f76#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bullet_back:before {
  content: "\e907";
}
.icon-xing:before {
  content: "\e900";
}
.icon-pfeil:before {
  content: "\e901";
}
.icon-pfeil_unten:before {
  content: "\e902";
}
.icon-pfeil_oben:before {
  content: "\e903";
}
.icon-kununu:before {
  content: "\e904";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-bullet:before {
  content: "\e906";
}
