$paddingFooterTop: 75;

footer {
  a { color: inherit; }
  > .inside {
    position: relative;
    @include wrapper;
    padding-top: dim($paddingFooterTop); padding-bottom: dim(30);
  }
}

.footer-contact {
  margin-bottom: dim(36);
  p {
    display: inline-block;
    &:first-child { width: dim(260); }
    &:nth-child(2) { width: dim(290); }
    @include fs(18,23,0);
    > a {
      @include font-semibold;
    }
  }
}

.footer-hotline {
  position: absolute; top: dim($paddingFooterTop); right: dim($paddingHorMain); z-index: 1;
  p:first-child {
    @include fs(18,18,0);
  }
  p.phone {
    //@include font-bold;
    padding-top: 4px;
    @include headline-special;
    @include fs(46,46,0);
  }
}

.footer-navigation {
  padding: dim(6) dim(3) 0 dim(4);
  text-align: right;
  @include fs(15,18,0);
  .copyright {
    float: left;
  }
  ul {
    display: inline-block;
    li {
      display: inline-block;
      &:not(:last-child):after {
        content: '|'; padding-left: dim(8); margin-right: dim(5);
      }
    }
  }
}


.homepage footer {
  background-color: $colorBgFooterRtec1;
  color: $colorTextFooterRtec1;
  .footer-navigation {
    border-top: 4px solid $colorBorderFooterRtec1;
  }
}
.content footer {
  background-color: $colorBgFooterContentRtec1;
  color: $colorTextFooterContentRtec1;
  .footer-navigation {
    border-top: 4px solid $colorBorderFooterContentRtec1;
  }
}

@include breakpoint('large down') {
  .footer-hotline {
    position: static;
    margin-bottom: dim(40);
    p.phone {
      @include fs(52,52,0);
    }
  }
}

@include breakpoint('medium down') {
  footer {
    > .inside {
      padding-top: dim($paddingFooterTop/2);
    }
  }

  .footer-contact {
    p {
      &:not(:last-child) { margin-bottom: dim(25); }
      //&:first-child { width: 100%; }
      //&:nth-child(2) { width: 100%; }
      &:last-child { width: 100%; }
    }
  }



  .footer-navigation {
    text-align: left;
    .copyright {
      float: none;
      display: block; margin-bottom: dim(10);
    }
  }
}

@include breakpoint('small down') {
  .footer-contact {
    p {
      &:first-child { width: 100%; }
      &:nth-child(2) { width: 100%; }
    }
  }

  .footer-hotline {
    p.phone {
      @include fs(35,35,0);
    }
  }

}