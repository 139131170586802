body {
  @include font-default;

  background-color: $colorBgBodyRtec1;

}

.article_padding_top {
  padding-top: dim(55);
  &.article_padding_extra {
    padding-top: dim(110);
  }
}
.article_padding_bottom {
  padding-bottom: dim(55);
  &.article_padding_extra {
    padding-bottom: dim(110);
  }
}

@include breakpoint('medium down') {
  .article_padding_top {
    &.article_padding_extra {
      padding-top: dim(55);
    }
  }
  .article_padding_bottom {
    &.article_padding_extra {
      padding-bottom: dim(55);
    }
  }

}


#main .mod_article {
  &.bg_hasimage {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

  }
  &.bg_blue {
    background-color: $colorBlue;
  }
}

#main .mod_article:not(.no-wrapper) .article_inside {
  @include wrapper;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.rainbow-top {
  background-image: url('images/rainbow.png');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 5px;
}

.bg-cloud {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url('images/bg_cloud.jpg');
}

@include breakpoint('medium down') {
  background-image: url('images/bg_cloud_medium.jpg');
}

@include breakpoint('small down') {
  background-image: url('images/bg_cloud_small.jpg');
}