.ce_accordion {
  color: black;

  //&:not(:first-child) {
  //  .toggler:not(.ui-state-active) {
  //
  //    //&:hover {
  //    //  border-top: 3px solid white;
  //    //  > div {
  //    //    border-top: 0;
  //    //  }
  //    //
  //    //  + .toggler {
  //    //    > div {
  //    //      border-top: 0;
  //    //    }
  //    //  }
  //    //}
  //
  //    > div {
  //      border-top: 3px solid black;
  //    }
  //  }
  //}


  &:first-child {
    .toggler {
      > div {
        border-top: 3px solid white;
      }
    }
  }
  &:not(:first-child) {
    .toggler {
      > div {
        border-top: 3px solid black;
      }
    }
  }

  &.active + .ce_accordion {
    .toggler {
      > div {
        border-top: 3px solid white;
      }
    }
  }

  //&.acc-style-white {
  //  background-color: white; color: black;
  //  &:hover, .ui-state-active, .ui-accordion-content {
  //    color: white;
  //    background-color: black;
  //  }
  //  &:hover, &.active, .ui-state-active {
  //    .toggler div {
  //      border-top: 3px solid  black !important;
  //    }
  //  }
  //}

  @for $i from 1 through 7 {
    &.acc-style-#{$i} {
      background-color: map-get($accStyle, $i);
      &:hover, .ui-state-active, .ui-accordion-content {
        background-color: map-get($accStyleHigh, $i);
      }
      &:hover, &.active, .ui-state-active {
        .toggler div {
          border-top: 3px solid  map-get($accStyleHigh, $i) !important;
        }
      }
    }
  }

  @for $i from 1 through 7 {
    &.acc-style-#{$i} {
      &:hover, .ui-state-active, .ui-accordion-content { color: map-get($accTextStyleHigh, $i); }
    }
  }

  &.acc-style-white {
    //.toggler {
    //  border-bottom:0 !important;
    //}
    //.ui-accordion-content {
    //  border-bottom: 0 !important;
    //  border-top: 3px solid black;
    //}
  }

  .toggler {
    > div {
      @include wrapper;
      padding-top: dim(23);
      padding-bottom: dim(23);
      //padding-left: dim($paddingLeftAccordion);
    }
  }

  //&:not(:first-child) {
  //  .toggler:not(.ui-state-active) {
  //
  //    //&:hover {
  //    //  border-top: 3px solid white;
  //    //  > div {
  //    //    border-top: 0;
  //    //  }
  //    //
  //    //  + .toggler {
  //    //    > div {
  //    //      border-top: 0;
  //    //    }
  //    //  }
  //    //}
  //
  //    > div {
  //      border-top: 3px solid black;
  //    }
  //  }
  //}

  //&.acc-style-white {
  //  .toggler {
  //    //border-top: 0 !important;
  //    > div {
  //      border-top: 3px solid black !important;
  //    }
  //  }
  //}



  .toggler h3 {
    // width: dim(400);
    position: relative;
    padding-right: dim(30);
    @include font-regular;
    @include fs(33,40,0);
    &:after {
      @include icon-arrow-down;
      @include fs(50,40,0);
      position: absolute; top:0; right:0; z-index: 1;
    }
  }

  &:last-child {
    .toggler h3 {
      @include font-semibold;
    }
  }


  .toggler.ui-accordion-header-active h3 {
    &:after {
      @include icon-arrow-up;
    }
  }

  .ui-accordion-content {
    //border-bottom: 3px solid black;
    > div {
      @include wrapper;
      padding-top: dim(45); padding-bottom: dim(55);

    }

  }

  //.ui-accordion-content {
  //  > div {
  //    border-top: 3px solid white;
  //  }
  //}

}

section.ce_accordion:hover + section.ce_accordion
{
  .toggler {
    > div {
      border-top: 3px solid white;
    }
  }
}

section.ce_accordion.active + section.ce_accordion
{
  .toggler {
    > div {
      //border-top:0 !important;
    }
  }
}

//
//section.ce_accordion:hover + .acc-style-white {
//  .toggler {
//    border-top: 3px solid white;
//    > div {
//      border-top: 0 !important;
//    }
//  }
//}
//
//section.ce_accordion.acc-style-white:hover {
//  .toggler {
//    border-top: 0 !important;
//  }
//}

.ui-accordion-content {

  .ce_text {
    ul {
      list-style-type: none;
    }
    li {
      margin-bottom: dim(10);
      > a {
        @include link-standard;
        color: white;
        &:before {
          color: inherit;
          top: -1px;
        }
      }
    }
    .image_container.float_left {
      float: left;
      img {
        max-width: 100%;
        height: auto;
      }
      & + .text_container {
        padding-left: dim($paddingLeftAccordion);
      }
    }

  }
  .text_container p {
    padding-right: dim(150);
  }

}

.ce_accordion {
  @for $i from 1 through 7 {

    &.acc-style-#{$i} {
      .ui-accordion-content {
        .ce_text,
        .ce_text li > a { color: map-get($accTextStyleHigh, $i); }

        > div {
          border-top: 3px solid map-get($accTextStyleHigh, $i);
        }
      }
    }
    //&.acc-style-white {
    //  .ui-accordion-content {
    //    .ce_text,
    //    .ce_text li > a { color: white; }
    //
    //    > div {
    //      border-top: 3px solid white;
    //    }
    //  }
    //}
  }

}


.special-contact {
  .text_container {
    p:nth-last-child(3) { margin-bottom: dim(40) }
    p:nth-last-child(2) { @include font-bold; @include fs(21,27,0); }
    p:last-child {
      @include headline-special;
      @include fs(55,55,20);
    }
    p:not(:last-child) {
      a {
        @include link-standard;
        color: inherit;
        &:before {
          color: inherit;
          top: -1px;
        }
      }
    }
  }
}

@include breakpoint('medium up') {
  .ce_accordion {
    //.toggler > div { padding-left: dim($paddingLeftAccordion + $paddingHorMain); }
  }
  .ui-accordion-content {
    .ce_text:not(.no-indent) {
      //padding-left: dim($paddingLeftAccordion);
    }
  }
}

@include breakpoint('medium down') {
  .ce_accordion {
    //.toggler > div { padding-left: 0; }
    .toggler h3 {
      width: 100%;
      @include fs(32,32,0);
      &:after {
        @include fs(45,32,0);
      }
    }

  }
  .ui-accordion-content {
    .ce_text:not(.no-indent) {
      //padding-left: 0;
    }
    .ce_text {
      .image_container.float_left {
        float: none;
        margin-bottom: dim(25);
        & + .text_container {
          padding-left: 0;
        }
      }
    }
    .text_container p {
      padding-right: 0;
    }
  }

}

@include breakpoint('small down') {

  .special-contact {
    .text_container {
      p:last-child { @include fs(35,35,20); }
    }
  }

}