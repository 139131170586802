@include breakpoint('medium down') {

  #main .mod_article:not(.no-wrapper) .article_inside,
  header > .inside,
  footer > .inside,
  .ce_accordion .toggler > div,
  .ce_accordion .ui-accordion-content > div {
    @include wrapperMedium;
  }

}

@include breakpoint('small down') {

  #main .mod_article:not(.no-wrapper) .article_inside,
  header > .inside,
  footer > .inside,
  .ce_accordion .toggler > div,
  .ce_accordion .ui-accordion-content > div {
    @include wrapperSmall;
  }

}



