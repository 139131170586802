@function dim($pxsize) {
  @return ($pxsize/16)+rem;
}


@mixin font-default {
  font-family: 'gtareg', sans-serif;
}
@mixin font-light {
  font-family: 'gtareg', sans-serif;
}
@mixin font-regular {
  font-family: 'gtareg', sans-serif;
}
@mixin font-semibold {
  font-family: 'gtamed', sans-serif;
}
@mixin font-bold {
  font-family: 'gtabold', sans-serif;
}
@mixin font-black {
  font-family: 'gtabold', sans-serif;
}

@mixin headline-special {
  font-family: 'gtamonoreg', sans-serif;
}

@mixin fs($fontsize, $lineheight, $margin) {
  $fontsizeRem: ($fontsize/16) * 1rem;
  font-size: $fontsizeRem;
  line-height: ($lineheight/$fontsize);
  margin-bottom: ($margin/$fontsize) * 1em;
}

@mixin paddingMain {
  padding-left: dim($paddingHorMain); padding-right: dim($paddingHorMain);
}

@mixin paddingMainMedium {
  padding-left: dim($paddingHorMain*4); padding-right: dim($paddingHorMain*4);
}

@mixin paddingMainSmall {
  padding-left: dim($paddingHorMain); padding-right: dim($paddingHorMain);
}

@mixin wrapper {
  max-width: 1200px;
  margin: 0 auto;
  @include paddingMain;
}

@mixin wrapperMedium {
  @include paddingMainMedium;
}

@mixin wrapperSmall {
  @include paddingMainSmall;
}

@mixin dimHeight($heightPx) {
  height: ($heightPx/16) * 1rem;
}

@mixin icon-arrow-right {
  font-family: icomoon; content: "\e901";
}

@mixin icon-arrow-left {
  font-family: icomoon; content: "\e907";
}

@mixin icon-arrow-up {
  font-family: icomoon; content: "\e903";
}

@mixin icon-arrow-down {
  font-family: icomoon; content: "\e902";
}

@mixin icon-button-next {
  font-family: icomoon; content: "\e906";
}

@mixin icon-button-prev {
  font-family: icomoon; content: "\e907";
}



@mixin arrow-before {
  position: relative;
  padding-left: dim(14);
  &:before {
    position: absolute; top: dim(10); left:0; z-index: 1;
    @include fs(7,7,0);
    font-family: icomoon; content: "\e901";
    color: inherit;
  }
}

@mixin dot-before {
  position: relative;
  padding-left: dim(30);
  &:before {
    position: absolute; top: 1px; left:0; z-index: 1;
    @include fs(23,27,0);
    font-family: icomoon; content: "\e906";
    color: $colorDotStandard;
  }
}

@mixin list-standard {
  ul {
    margin-bottom: dim(15);
    max-width: 100%;
  }
  li {
    @include fs(20,28,6);
    @include dot-before;
  }
}

@mixin link-standard {
  display: inline-block;
  @include font-bold;
  @include fs(19,24,0);
  @include dot-before;
}


@mixin breakpoint($point) {
  @if $point == 'large up' {
    @media (min-width: 1200px) { @content ; }
  }
  @else if $point == 'large down' {
    @media (max-width: 1200px)  { @content ; }  // 1200px
  }
  @else if $point == 'medium up' {
    @media (min-width: 60em)  { @content ; }  // 960px
  }
  @else if $point == 'medium down' {
    @media (max-width: 60em)  { @content ; }  // 960px
  }
  @else if $point == 'small down' {
    @media (max-width: 40em)  { @content ; }
  }
}