@import 'base/reset';
@import 'base/typography';
@import 'base/font_custom';
@import 'base/variables';
//@import 'vendor/gridlex/gridlex';
@import 'base/mixins';
@import 'framework/layout';

@import 'base/_general';

@import 'content/header';
@import 'content/footer';
@import 'content/grid';
@import 'content/elements';
@import 'content/accordion';
@import 'base/responsive';




