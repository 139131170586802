// Headlines

.content-headline {
  padding-top: dim($fontsizeH1Large/2);
  background-color: $colorBgHeadlineContent;
  h1 {
    @include headline-special;
    @include fs($fontsizeH1Large,$fontsizeH1Large,50);
    color: $colorTextHeadlineContent;
    position: relative; //top: dim(14);
  }
}

.mac .content-headline h1 {
  //top: dim(22);
}

h2.ce_headline {
  @include font-bold;
  @include fs($fontsizeH2Large,$fontsizeH2Large*1.25,$fontsizeH2Large/2);


  > a {
    position: relative;
    display: block;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      @include icon-arrow-right;
      @include fs($fontsizeH2Large,$fontsizeH2Large*1.25,0);
      color: black;
      padding-left: dim(19);
    }
  }
}


// Text, List

.ce_text {
  p, li {
    @include font-light;
    @include fs(20,27,15);
  }
  &.two-columns {
    column-count: 2;
    column-gap: dim(80);
  }
  p > a {
    @include link-standard;
  }

}

.homepage {
  .ce_text {
    p, li {
      @include fs(18,26,15);
    }
  }
}

.ce_list {
  li {
    @include font-light;
  }
}

.ce_list, .content .ce_text {
  @include list-standard;
}


.content #main {
  .ce_text, .ce_list {
    padding-left: dim(147); padding-right: dim(147);
    //margin-bottom: dim(50);
  }
}

.content #main {
  .ce_text, .ce_list {
    & + .ce_headline, & + .ce_hyperlink {
      margin-top: dim(50);
    }
  }
}

.intro-quote, .intro-adwords {
  color: white;
  h2 {
    @include font-bold;
  }
  .text_container {
    p {
      @include fs(18,24,15);
    }
  }
}

.intro-quote-image {
  img {
    width: 75px;
    height: auto;
  }
  margin-bottom: 20px;
}

.intro-quote {
  h2 {
    @include fs(65, 65, 30);
  }
  //padding-left: dim(200);
  //background: url('images/bg_quote.png') no-repeat left dim(15) ;
}

.intro-quote-left {
  width: 50%;
  padding-right: 20px;
  float: left;
  text-transform: uppercase;
}

.intro-quote-right {
  width: 50%;
  padding-left: 20px;
  float: right;
}

.intro-adwords {
  padding-top: dim(20);
  h1 {
    @include font-bold;
    @include fs($fontsizeH1AwLarge, $fontsizeH1AwLarge, 30);
    text-transform: uppercase;
  }
  h2 {
    @include fs($fontsizeH2Large, $fontsizeH2Large, 30);
  }
  p {
    padding-right: dim(150);
  }
}

.aw {
  .intro-adwords {
    h1 {
      color: $colorBgAdwords2;
    }
  }
}

.quote-dark {
  background-color: $colorBgQuoteDark;
  color: $colorTextQuoteDark;
  .article_inside {
    //background: url('images/bg_quote.png') no-repeat right top;
    position: relative;
    &:before {
      //position: absolute; top:0; right:0; z-index: 1;
      //font-family: icomoon; content: "\e905"; font-size: dim(150);
      //color: black;
    }
  }
  .ce_text {
    li {
      @include font-regular;
      padding-left: dim(25);
      &:before {
        content: '\e906' !important;
        @include fs(23,27,0);
        color: white;
      }
      margin-bottom: dim(8) !important;
      //font-style: italic;
    }
  }
}

.style-aw .quote-dark {
  background-color: $colorBgAdwords1;
  .article_inside:before { color: $colorBgAdwords2; }
  .ce_text li:before { color: $colorBgAdwords2; }
}

.text-white {
  color: white;
}

// Hyperlinks

.ce_hyperlink:not([class*="button"]) {
  a {
    @include link-standard;
  }
}

.ce_hyperlink.button-mail {
  width: 49%; display: inline-block;
  //width: 50%; margin-right: 50%;
  //float:left;
  > a {
    display: inline-block; padding: dim(16) dim(30);
    background-color: black; color: white;
    @include font-bold;
    @include fs(30,30,0);

    &:after {
      @include icon-button-next;
      @include fs(30,30,0);
      position: relative; top: 5px;
      padding-left: dim(25);
    }
  }
}

.ce_hyperlink.button-back {
  width: 50%; display: inline-block; text-align: right;
  //float: right;
  margin-top:0 !important;
  > a {
    display: inline-block; padding: dim(16) dim(30);
    background-color: white; color: black;
    border: 3px solid black;
    @include font-bold;
    @include fs(30,30,0);

    &:before {
      @include icon-button-prev;
      @include fs(30,30,0);
      padding-right: dim(25);
      position: relative; top: 5px;
    }
  }
}

@for $i from 1 through 14 {
  .style#{$i} {
    .content-headline { background-color: map-get($accStyleHigh, $i); }
    .ce_list, .ce_text {
      //li:before { color: map-get($accStyleHigh, $i); }
      li:before { color: black; }
    }
    .ce_hyperlink.button-mail > a { background-color: map-get($accStyleHigh, $i); }
  }
}

.style-aw {
  .content-headline { background-color: $colorBgAdwords2; }
  .ce_list, .ce_text {
    li:before { color: $colorBgAdwords1; }
  }
}


@include breakpoint('medium up') {
}

@include breakpoint('medium down') {
  .content-headline {
    padding-top: dim($fontsizeH1Medium/2);

  }
  .content-headline h1 {
    @include fs($fontsizeH1Medium, $fontsizeH1Medium, 30);
    //top: dim(11);
  }
  .mac .content-headline h1 {
    //top: dim(15);
  }

  h2.ce_headline {
    @include fs($fontsizeH2Medium, $fontsizeH2Medium*1.25, $fontsizeH2Medium/2);
    > a {
      &:after {
        @include fs($fontsizeH2Medium,$fontsizeH2Medium*1.25,0);
      }
    }
  }

  .ce_text {
    &.two-columns { column-count: 1; }
  }

  .content #main {
    .ce_text, .ce_list {
      padding-left: 0; padding-right: 0;
    }
  }

  .intro-quote {
    //padding-left: dim(0); padding-top: dim(10);
    //background: url('images/bg_quote.png') no-repeat right top;
  }

  .intro-quote {
    h2 {
      @include fs($fontsizeH1Medium, $fontsizeH1Medium, 30);
    }
  }

  .intro-quote-left {
    width: 100%;
    padding-right: 0;
    float: none;
  }

  .intro-quote-right {
    width: 100%;
    padding-left: 0;
    float: none;
  }


  .intro-adwords {
    h1 {
      @include fs($fontsizeH1AwMedium, $fontsizeH1AwMedium, 30);
    }
    h2 {
      @include fs($fontsizeH2Medium, $fontsizeH2Medium, 30);
    }
    p {
      padding-right: 0;
    }
  }

  .ce_hyperlink.button-mail, .ce_hyperlink.button-back {
    width: 100%; text-align: left;
  }
  .ce_hyperlink.button-mail {
    margin-bottom: dim(30);
  }
}

@include breakpoint('small down') {
  .content-headline {
    padding-top: dim($fontsizeH1Small/2);
  }
  .content-headline h1 {
    @include fs($fontsizeH1Small, $fontsizeH1Small, 20);
    //top: dim(7);
  }
  .mac .content-headline h1 {
    //top: dim(10);
  }
  .intro-quote {
    h2 {
      @include fs($fontsizeH1Small, $fontsizeH1Small, 30);
    }
  }

  .ce_hyperlink.button-mail {
    text-align: center;
    > a {
      display: block; padding: dim(15) dim(20);
      @include fs(30,30,0);

      &:after {
        @include fs(18,30,0);
        padding-left: dim(15);
      }
    }
  }

  .ce_hyperlink.button-back {
    text-align: center;
    > a {
      display: block; padding: dim(15) dim(20);
      @include fs(30,30,0);

      &:before {
        @include icon-arrow-left;
        @include fs(18,30,0);
        padding-right: dim(15);
      }
    }
  }

}
