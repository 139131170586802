header {
  @include dimHeight($headerHeight); width: 100%;
  position: fixed; top:0; left:0; z-index:100;
  > .inside {
    position: relative;
    @include paddingMain;
    //@include dimHeight($headerHeight);
    @include wrapper;
    height: inherit;
  }
}

.homepage header {
  background-color: $colorBgHeaderRtec1;
}
.content header {
  background-color: $colorBgHeaderContentRtec1;
}

#container {
  padding-top: dim($headerHeight);
}

.header-logo {
  position: absolute; top:50%; left: dim($paddingHorMain); z-index: 1;
  transform: translateY(-50%);
  width: 166px; height: 68px; background: url('images/bg_logo.png') no-repeat top left;
  background-size: contain;
  a {
    display: inline-block; width: inherit; height: inherit;
  }
}
.content .header-logo { background-image: url('images/bg_logo_dark.png'); }


// Social media links

.header-sml {
  position: absolute; top:50%; right: dim($paddingHorMain); z-index: 1;
  transform: translateY(-50%);
  ul {
    display: inline-block;
    li {
      display: inline-block;
      @include fs(33,33,0);
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  a {
    color: inherit;
  }
}

.homepage .header-sml {
  color: $colorTextSocialMedia;
}

.content .header-sml {
  color: $colorTextSocialMediaContent;
}


// Parallax Header

.header-parallax {
  visibility: visible;
  position: fixed; top: dim($headerHeight); left: 0px; z-index: -100;
  overflow: hidden; transform: translate3d(0px, 0px, 0px);
  height: dim($headerImageHeight);
  width: 100%;

  background: url('images/bg_header_home.jpg') no-repeat top center;
  //background: url('images/bg_header_home_wo_typo.jpg') no-repeat top center; background-size: cover;
  background-blend-mode: darken;
  transition: opacity 3s;

  text-align: center;

  opacity: 0;
  &.active {
    opacity: 1;
  }

  &.hp1 { background: url('images/bg_header_home_1.jpg') no-repeat top center; }
  &.hp2 { background: url('images/bg_header_home_2.jpg') no-repeat top center; }
  &.hp3 { background: url('images/bg_header_home_3.jpg') no-repeat top center; }
  &.hp4 { background: url('images/bg_header_home_4.jpg') no-repeat top center; }
  &.hp5 { background: url('images/bg_header_home_5.jpg') no-repeat top center; }
}

.header-image {
  height: dim($headerImageHeight);
  opacity: 0;
  display: block;
  overflow: hidden;
}


.header-claim {
  max-width: dim(1200); margin:0 auto;

  span {
    display: block;
    margin-top: dim(150);
    padding-left: dim($paddingHorMain);
    @include font-bold; @include fs(90,86,0);
    color: $colorTextClaim1; text-transform: uppercase; text-align: left;
  }

}

.aw {
  #hp1 { background: url('images/adwords/bg_header_home_pig.jpg') no-repeat top center; }
  #hp2 {
    background: url('images/adwords/bg_header_home_leo.jpg') no-repeat top center;
    .header-claim span { color: $colorTextClaim2; }
  }
}

/*
.aw1 { .header-parallax { background: url('images/adwords/bg_header_home_pig.jpg') no-repeat top center; } }
.aw2 { .header-parallax { background: url('images/adwords/bg_header_home_nerd1.jpg') no-repeat top center; } }
.aw3 { .header-parallax { background: url('images/adwords/bg_header_home_nerd2.jpg') no-repeat top center; } }
.aw4 {
  .header-parallax { background: url('images/adwords/bg_header_home_leo.jpg') no-repeat top center; }
  .header-claim span { color: $colorTextClaim2; }
}
*/

//.header-claim { color: $colorTextClaim1; }


@include breakpoint('medium down') {

  .header-claim {
    display: none;
  }

  header { @include dimHeight($headerHeightMedium); }
  #container { padding-top: dim($headerHeightMedium); }

  .header-image { height: dim($headerImageHeightMedium); }
  .header-parallax {
    height: dim($headerImageHeightMedium);
    background: url('images/bg_header_home_medium.jpg') no-repeat top center;
    top: dim($headerHeightMedium);
  }

  .header-logo { width: 116px; height: 46px; background: url('images/bg_logo_medium.png') no-repeat top left;
    background-size: contain;
  }
  .content .header-logo { background: url('images/bg_logo_dark_medium.png') no-repeat top left; }
  .header-sml ul li { @include fs(33*$scaleMedium, 33*$scaleMedium, 0); }

  .aw1 { .header-parallax { background: url('images/adwords/bg_header_home_pig_medium.jpg') no-repeat top center; } }
  .aw2 { .header-parallax { background: url('images/adwords/bg_header_home_nerd1_medium.jpg') no-repeat top center; } }
  .aw3 { .header-parallax { background: url('images/adwords/bg_header_home_nerd2_medium.jpg') no-repeat top center; } }
  .aw4 { .header-parallax { background: url('images/adwords/bg_header_home_leo_medium.jpg') no-repeat top center; } }

  .aw {
    #hp1 { background: url('images/adwords/bg_header_home_pig_medium.jpg') no-repeat top center; }
    #hp2 { background: url('images/adwords/bg_header_home_leo_medium.jpg') no-repeat top center; }
  }

  .header-parallax {
    &.hp1 { background: url('images/bg_header_home_medium_1.jpg') no-repeat top center; }
    &.hp2 { background: url('images/bg_header_home_medium_2.jpg') no-repeat top center; }
    &.hp3 { background: url('images/bg_header_home_medium_3.jpg') no-repeat top center; }
    &.hp4 { background: url('images/bg_header_home_medium_4.jpg') no-repeat top center; }
    &.hp5 { background: url('images/bg_header_home_medium_5.jpg') no-repeat top center; }
  }

}

@include breakpoint('small down') {
  header { @include dimHeight($headerHeightSmall); }
  #container { padding-top: dim($headerHeightSmall); }

  .header-image { height: dim($headerImageHeightSmall); }
  .header-parallax {
    height: dim($headerImageHeightSmall);
    background: url('images/bg_header_home_small.jpg') no-repeat top center;
    top: dim($headerHeightSmall);
  }

  .header-logo { width: 83px; height: 33px; background-image: url('images/bg_logo_small.png'); }
  .content .header-logo { background-image: url('images/bg_logo_dark_small.png'); }
  .header-sml ul li { @include fs(33*$scaleSmall, 33*$scaleSmall, 0); }

  .aw1 { .header-parallax { background: url('images/adwords/bg_header_home_pig_small.jpg') no-repeat top center; } }
  .aw2 { .header-parallax { background: url('images/adwords/bg_header_home_nerd1_small.jpg') no-repeat top center; } }
  .aw3 { .header-parallax { background: url('images/adwords/bg_header_home_nerd2_small.jpg') no-repeat top center; } }
  .aw4 { .header-parallax { background: url('images/adwords/bg_header_home_leo_small.jpg') no-repeat top center; } }

  .aw {
    #hp1 { background: url('images/adwords/bg_header_home_small.jpg') no-repeat top center; }
    #hp2 { background: url('images/adwords/bg_header_home_small.jpg') no-repeat top center; }
  }

  .header-parallax {
    &.hp1 { background: url('images/bg_header_home_small_1.jpg') no-repeat top center; }
    &.hp2 { background: url('images/bg_header_home_small_2.jpg') no-repeat top center; }
    &.hp3 { background: url('images/bg_header_home_small_3.jpg') no-repeat top center; }
    &.hp4 { background: url('images/bg_header_home_small_4.jpg') no-repeat top center; }
    &.hp5 { background: url('images/bg_header_home_small_5.jpg') no-repeat top center; }
  }


}